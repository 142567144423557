/* eslint-disable no-bitwise */
import {
  Permission,
  PermissionModule,
  Role,
} from '@memberspot/shared/model/permission';
import { ObjectId } from 'mongodb';

export const generalPermissionChecker = (
  moduleToCheck: PermissionModule,
  permissionToCheck: Permission,
  role: Role | Role<ObjectId> | undefined | null,
) => {
  const permission = role?.[moduleToCheck] as number;

  return (permission & permissionToCheck) === permissionToCheck;
};

export const generalCoursePermissionChecker = (
  courseId: string,
  permissionToCheck: Permission,
  role: Role | undefined | null,
) =>
  role?.allCourses
    ? checkPermissionAllCourses(permissionToCheck, role)
    : checkPermissionCourseSpecific(courseId, permissionToCheck, role);

const checkPermissionAllCourses = (
  permissionToCheck: Permission,
  role: Role | undefined | null,
) =>
  ((role?.allCoursesPermission || 0) & permissionToCheck) === permissionToCheck;

const checkPermissionCourseSpecific = (
  courseId: string,
  permissionToCheck: Permission,
  role: Role | undefined | null,
) =>
  ((role?.courses?.[courseId] || 0) & permissionToCheck) === permissionToCheck;
